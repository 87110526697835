@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}
:root {
  --blue: #38ace3;
  --accentBlue: #119ada;
  --white: #fff;
  --grey: #e5e5e5;
  --black1: #222;
  --black2: #999;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

h1 {
  font-weight: 600;
  text-transform: uppercase;
  color: var(--black2);
  font-size: 2em;
  margin-top: 5px;
}

h2 {
  font-weight: 500;
  color: var(--black2);
  font-size: 2em;
}

h3 {
  font-weight: 500;
  font-size: 30pt;
  color: var(--black2);
}

h4 {
  font-weight: 500;
  color: var(--black2);
  font-size: 1em;
}

h5 {
  font-weight: 400;
  font-size: 20pt;
  color: var(--black2);
}

p {
  font-weight: 400;
  font-size: 20pt;
  color: var(--black2);
}

label {
  font-weight: 300;
  font-size: 18pt;
  color: var(--black2);
}

.container {
  position: relative;
  width: 100%;
}

.main-panel {
  position: relative;
  width: 100%;
  padding: 20px;
}

.text-center {
  text-align: center;
}

.ancla-nav {
  position: relative;
  display: block;
  width: 50%;
  display: flex;
  text-decoration: none;
  color: var(--white);
  cursor: pointer;
}

ancla-nav:hover {
  color: var(--blue);
}

.rdw-wrapper-8282 {
  border: 1px solid #080707;
  border-radius: 2px;
}

.span-custom {
  color: var(--black2);
  margin-left: 8px;
}
